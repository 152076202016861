.navbar-ccts {
    background-color: #363237;
}

.navbar-brand {
    color: #fefeff;
    font-weight: 550;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, .80)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar .nav-link, .navbar .dropdown-toggle {
    color: #ffffff;
    font-size: 1rem;
}

.navbar .nav-link:hover, .navbar .dropdown-toggle:hover {
    color: #cccccc;
}

.custom-dropdown .dropdown-menu {
    background-color: #363237;
}

.custom-dropdown .dropdown-item {
    color: #ffffff;
}

.custom-dropdown .dropdown-item:hover, .custom-dropdown .dropdown-item:focus {
    color: #cccccc;
    background-color: #4e4e4e;
}
